import { Box, Button, Grid, GridProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatISO, startOfDay, subBusinessDays } from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";

import { DateRangeButton } from "@APP/types";

type SetFieldValueType = (field: string, value: string) => void;

type Props = {
  fieldValueSetter: SetFieldValueType;
  values: any;
  onClick?: () => void;
};

type CommonDateRangeSelectionButtonProps = DateRangeButton & Props;

type CommonDateRangeSelectionButtonsProps = Props & {
  contentJustify?: GridProps["justifyContent"];
  setCustomDatePicker?: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme) => ({
  buttonBox: {
    spacing: theme.spacing(1),
  },
  buttonGridContainer: {
    spacing: theme.spacing(2),
  },
}));

export const commonDateRanges: DateRangeButton[] = [
  {
    label: "Today",
    from: formatISO(startOfDay(new Date())),
    to: formatISO(new Date()),
    active: true,
  },
  {
    label: "Last 7 days",
    from: formatISO(subBusinessDays(new Date(), 7)),
    to: formatISO(new Date()),
    active: false,
  },
  {
    label: "Last 30 days",
    from: formatISO(subBusinessDays(new Date(), 30)),
    to: formatISO(new Date()),
    active: false,
  },
  {
    label: "Custom",
    from: formatISO(new Date()),
    to: formatISO(new Date()),
    active: false,
  },
];

const CommonDateRangeSelectionButton = ({
  label,
  from,
  to,
  fieldValueSetter,
  active,
  onClick,
}: CommonDateRangeSelectionButtonProps) => (
  <Grid item xs={3}>
    <Button
      key={label}
      variant={active ? "contained" : "outlined"}
      fullWidth={true}
      type={label === "Custom" ? "button" : "submit"}
      onClick={() => {
        onClick && onClick();
        fieldValueSetter("from", from);
        fieldValueSetter("to", to);
      }}>
      {label}
    </Button>
  </Grid>
);

const CommonDateRangeSelectionButtons = ({
  fieldValueSetter,
  contentJustify,
  values,
  setCustomDatePicker,
}: CommonDateRangeSelectionButtonsProps) => {
  const classes = useStyles();

  const [customDateRange, setCustomDateRange] = useState(commonDateRanges);

  const handleActiveButton = (label: string) => {
    setCustomDatePicker && setCustomDatePicker(false);
    if (label === "Custom" && setCustomDatePicker) setCustomDatePicker(true);

    const activeButton = customDateRange.map((button) =>
      button.label === label ? { ...button, active: true } : { ...button, active: false },
    );
    setCustomDateRange(activeButton);
  };

  return (
    <Box className={classes.buttonBox}>
      <Grid container spacing={1}>
        <Grid
          container
          item
          className={classes.buttonGridContainer}
          spacing={1}
          justifyContent={contentJustify}>
          {customDateRange.map(({ label, from, to, active }: DateRangeButton) => (
            <CommonDateRangeSelectionButton
              key={label}
              label={label}
              from={from}
              to={to}
              values={values}
              fieldValueSetter={fieldValueSetter}
              active={active}
              onClick={() => handleActiveButton(label)}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonDateRangeSelectionButtons;
