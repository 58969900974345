import { Box, Card, TableCell, TableRow } from "@mui/material";

import { ScreenHeader } from "@APP/components";
import { OrgSortBy } from "@APP/types";
import { OrgTable } from "@APP/components/organisation";

import OrganisationRows from "./OrganisationRows";
import { Organisation, OrganisationData } from "./OrganisationDashboardView";

interface HeadCell {
  id: OrgSortBy;
  rightAlign: boolean;
  disablePadding: boolean;
  label: string;
}

interface OrganisationListProps {
  data: OrganisationData;
  entries: number;
  page: number;
  lastPage?: number;
  setPage?: (page: number) => void;
  setEntries?: (entries: number) => void;
  handleOnEntriesChange: (entries: number) => void;
}

const HEADER_CELL_ITEMS: HeadCell[] = [
  { id: OrgSortBy.collapseIcon, rightAlign: true, disablePadding: true, label: "" },
  { id: OrgSortBy.orgName, rightAlign: true, disablePadding: false, label: "Name" },
  { id: OrgSortBy.status, rightAlign: true, disablePadding: false, label: "Status" },
  {
    id: OrgSortBy.subscriptionStatus,
    rightAlign: false,
    disablePadding: false,
    label: "Subscription Status",
  },
  {
    id: OrgSortBy.enableOrganisation,
    rightAlign: true,
    disablePadding: true,
    label: "Enable/Disable",
  },
];

const EMPTY_ROW_COL_SPAN = HEADER_CELL_ITEMS.length + 2;

const OrganisationList = ({
  data,
  entries,
  page,
  lastPage,
  setPage,
  handleOnEntriesChange,
}: OrganisationListProps) => {
  const renderHeader = () => (
    <TableRow>
      {HEADER_CELL_ITEMS.map(({ id, label, disablePadding }) => (
        <TableCell key={id} align="left" padding={disablePadding ? "none" : "normal"}>
          <Box display="flex" alignItems="center" data-testid={`${label}-field-label`}>
            {label}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );

  const RenderRows = ({ orgId, orgName, status, subscriptionStatus, users }: Organisation) => {
    return (
      <OrganisationRows
        orgId={orgId}
        orgName={orgName}
        status={status}
        subscriptionStatus={subscriptionStatus}
        users={users}
      />
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={EMPTY_ROW_COL_SPAN} align="center">
        No Matching Organisations found.
      </TableCell>
    </TableRow>
  );

  return (
    <Box my={4}>
      <ScreenHeader title="Organisation" />
      <Card elevation={12}>
        <OrgTable
          renderHeader={renderHeader}
          data={data?.data || []}
          showPagination={true}
          renderRows={RenderRows}
          onEntriesChange={handleOnEntriesChange}
          onPageChange={setPage}
          entries={entries}
          page={page}
          lastPage={lastPage}
          renderEmptyDataRow={renderEmptyDataRow}
        />
      </Card>
    </Box>
  );
};

export default OrganisationList;
