import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { formatInTimeZone } from "date-fns-tz";

import { FooterActionsButtons, Page, ScreenHeader, Table } from "@APP/components";
import { MetroBankAccountStatuses } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { SCREEN_PATHS } from "@APP/navigation";
import { AppState, hideLoader, setSelectedUser, showLoader } from "@APP/redux";
import { API } from "@APP/services";
import { tableStyles as useStyles } from "@APP/styles";
import { ADMIN_ACTIONS, MetroBankAccountDetails, UserSearchResponse } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";
import { getMetroBankAccountsDetails } from "@APP/services/api";
import CONFIG from "@APP/config";

const UserDetailsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();
  const [bankDetailsFound, setBankDetailsFound] = useState(false);
  const [metroBankAccountInfo, setMetroBankAccountInfo] = useState<
    MetroBankAccountDetails | undefined
  >();

  const { selectedUser } = useSelector(
    ({
      userAdmin: {
        users: { selectedUser },
      },
    }: AppState) => ({
      selectedUser,
      // usersRTP's endpoint not yet ready
      // usersRTPS,
    }),
  );

  const { userId }: { userId: string } = useParams();

  const getMetroBankDetails = async () => {
    if (!selectedUser || !CONFIG.FEATURES?.CONFIRMATION_OF_PAYEE) return;

    try {
      dispatch(showLoader());
      const data = await getMetroBankAccountsDetails(selectedUser?.orgId);
      setBankDetailsFound(true);
      setMetroBankAccountInfo(data);
    } catch (e) {
      setBankDetailsFound(false);
      setMetroBankAccountInfo(undefined);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    (async () => {
      if (!selectedUser) fetchUserDetails(userId);
      if (selectedUser) setFieldValue("username", selectedUser.username);
      await getMetroBankDetails();
    })();
  }, [selectedUser, userId]);

  const fetchUserDetails = async (userId: string) => {
    try {
      dispatch(showLoader());
      const results = await API.getSearchedUsers({
        username: userId,
      });
      const matchedUser = results.data.find((user: UserSearchResponse) => user.username === userId);
      matchedUser ? dispatch(setSelectedUser(matchedUser)) : history.push(SCREEN_PATHS.UPDATE_USER);
    } catch (error) {
      alert.open("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  /*
  // Waiting on backend to build the endpoint for this to fetch RTP's for a given user, leaving commented out until ready.
  useEffect(() => {
    const fetchRTPS = async () => {
      try {
        dispatch(showLoader())
        const data = await API.getRTPSofUser(userId);
        dispatch(getRTPSofUser(data));
      } catch (error) {
        alert("Error", formatErrorMessage(error));
      }
      dispatch(hideLoader());
    };
    fetchRTPS();
  }, []);
  */

  const handleDisableEnableMetro = async (type: "disable" | "enable") => {
    try {
      dispatch(showLoader());
      await API.disableOrEnableBankAccount(type, selectedUser!.orgId);
      history.push(SCREEN_PATHS.UPDATE_USER_SUCCESS);
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open("Failure", "We have been unable to update this user, please try again");
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleDeleteMetro = async () => {
    try {
      dispatch(showLoader());
      await API.deleteBankAccount(selectedUser!.orgId);
      history.push(SCREEN_PATHS.UPDATE_USER_SUCCESS);
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open("Failure", "We were unable to delete the bank account details, try again later");
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleBlockUnblockUser = async (username: string, blocked: boolean) => {
    try {
      dispatch(showLoader());
      await API.changeUserBlockStatus(username, blocked);
      history.push(SCREEN_PATHS.UPDATE_USER_SUCCESS);
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open("Failure", "We were unable to delete the bank account details, try again later");
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleOnSubmit = async ({
    username,
    action,
  }: {
    username: string;
    action: ADMIN_ACTIONS | string;
  }) => {
    switch (action) {
      case ADMIN_ACTIONS.Block:
        await handleBlockUnblockUser(username, true);
        break;
      case ADMIN_ACTIONS.Unblock:
        await handleBlockUnblockUser(username, false);
        break;
      case ADMIN_ACTIONS.DeleteMetroBank:
        await handleDeleteMetro();
        break;
      case ADMIN_ACTIONS.DisableMetroBank:
        await handleDisableEnableMetro("disable");
        break;
      case ADMIN_ACTIONS.EnableMetroBank:
        await handleDisableEnableMetro("enable");
        break;
    }
  };

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: {
      username: "",
      action: "",
    },
    onSubmit: handleOnSubmit,
  });

  if (!selectedUser) return null;

  const { customerId, username, phone } = selectedUser;

  // const formattedLastLogin = lastLoginTime
  //   ? format(new Date(lastLoginTime), mediumFormatDateTime)
  //   : "";

  const renderFirstHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell>User Name</TableCell>
      <TableCell>Organisation Name</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell />
    </TableRow>
  );

  const getContentMetroInfoAlert = () => (
    <Box m={4}>
      <Box mb={4}>
        <Box borderBottom="1px solid silver">
          <Typography variant="h5">Metro Bank Details</Typography>
          <Box py={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Account Holder Name:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.customerName}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Sort Code:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.sortCode}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Account Number:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.accountNumber}</Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">COP Result:</Typography>
          <Box py={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Requested CustomerName:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.customerName}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">ReturnedCustomerName:</Typography>
              <Typography variant="subtitle2">
                {metroBankAccountInfo?.returnedCustomerName || "-"}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">Result:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.result.toString()}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">ResultText:</Typography>
              <Typography variant="subtitle2">{metroBankAccountInfo?.resultText || "-"}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">NameMatchResult:</Typography>
              <Typography variant="subtitle2">
                {metroBankAccountInfo?.nameMatchResult || "-"}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">AccountTypeResult:</Typography>
              <Typography variant="subtitle2">
                {metroBankAccountInfo?.accountTypeResult.toString()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" width="100%">
        <Button variant="contained" color="primary" onClick={() => alert.close()}>
          Okay
        </Button>
      </Box>
    </Box>
  );

  const openMetroInfoAlert = () => {
    alert.render(getContentMetroInfoAlert());
  };

  const renderFirstRow = () => (
    <Fragment key={customerId}>
      <TableRow>
        <TableCell>{username}</TableCell>
        <TableCell>{selectedUser?.orgName ? selectedUser.orgName : ""}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          Last Login:{" "}
          {selectedUser.meta.lastLoginTime
            ? formatInTimeZone(selectedUser.meta.lastLoginTime, "UTC", "yyyy-MM-dd")
            : "-"}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell>
          User Role: {selectedUser?.roles.length > 0 ? "Administrator" : "User"}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell>Status: {selectedUser?.status}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </Fragment>
  );
  console.log(bankDetailsFound);

  return (
    <Container maxWidth={false}>
      <Page title="User Details">
        <Grid container>
          <Grid item xs={12} md={6}>
            <ScreenHeader title="View User" />
          </Grid>
          {CONFIG.FEATURES?.CONFIRMATION_OF_PAYEE && metroBankAccountInfo && selectedUser?.orgId && (
            <Grid item xs={12} md={6}>
              <Box display="flex" textAlign="center" justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={openMetroInfoAlert}>
                  Show COP results and Bank Details
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        <form onSubmit={handleSubmit}>
          <Box my={3}>
            <Card elevation={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table
                    data={[selectedUser]}
                    renderHeader={renderFirstHeader}
                    renderRows={renderFirstRow}
                    showPagination={false}
                  />
                </Grid>
              </Grid>{" "}
              <Box p={2}>
                <Grid container item>
                  <Grid item lg={4} xs={false} />
                  <Grid item lg={4} xs={12}>
                    {!bankDetailsFound ? (
                      <>
                        {" "}
                        <Typography
                          sx={{ display: "flex", justifyContent: "center" }}
                          variant="h5"
                          gutterBottom>
                          No bank details found for the Organisation
                        </Typography>
                      </>
                    ) : (
                      <Paper elevation={4}>
                        <TextField
                          fullWidth
                          select
                          label="Select action"
                          name="action"
                          value={values.action}
                          onChange={handleChange}
                          data-testid="user-actions-select"
                          variant="outlined">
                          {CONFIG.FEATURES?.CONFIRMATION_OF_PAYEE && metroBankAccountInfo && (
                            <MenuItem value={ADMIN_ACTIONS.DeleteMetroBank}>
                              Delete Metro Bank Account
                            </MenuItem>
                          )}
                          {CONFIG.FEATURES?.CONFIRMATION_OF_PAYEE &&
                            metroBankAccountInfo &&
                            metroBankAccountInfo.status === MetroBankAccountStatuses.disabled && (
                              <MenuItem value={ADMIN_ACTIONS.EnableMetroBank}>
                                Enable Metro Accounts
                              </MenuItem>
                            )}
                          {CONFIG.FEATURES?.CONFIRMATION_OF_PAYEE &&
                            metroBankAccountInfo &&
                            metroBankAccountInfo.status === MetroBankAccountStatuses.enabled && (
                              <MenuItem value={ADMIN_ACTIONS.DisableMetroBank}>
                                Disable Metro Accounts
                              </MenuItem>
                            )}
                        </TextField>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <FooterActionsButtons
              backButtonText="Back to Search Results"
              handleBackButton={() => history.push(SCREEN_PATHS.UPDATE_USER)}
              continueButtonText="Apply"
              typeButtonContinue="submit"
            />
          </Box>
        </form>
      </Page>
    </Container>
  );
};

export default UserDetailsView;
