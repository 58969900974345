import CONFIG from "@APP/config";
import {
  RequestPagination,
  ResponsePagination,
  OrganisationSearchTerm,
  OrganisationData,
} from "@APP/types";

import request from "../request";

export const getSearchedOrganisations = async (
  searchTerms: OrganisationSearchTerm,
  pagination: RequestPagination = { page: 0, entries: 25 },
) => {
  const { page = 0, entries = 25 } = pagination;
  const response = await request<{
    data: OrganisationData[];
    links: ResponsePagination;
    meta: { totalItems: number };
  }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/organisations/search`,
    params: {
      page: `${page * entries};;;${entries}`,
      ...searchTerms,
    },
  });

  return response.data;
};

export const changeOrganisationBlockStatus = async (orgId: string, blocked: boolean) => {
  const response = await request({
    method: blocked ? "DELETE" : "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/organisation/blocked`,
    data: { orgId: orgId },
  });

  return response;
};
